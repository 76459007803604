import React from 'react';
import { useUser } from '../userContext';
import { Typography, Grid, Card, CardContent,Button, Divider, IconButton, TextField, InputAdornment, Avatar } from "@mui/material";
import { useStyles } from '../../../styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkAuth, loginUser, verifyFacebookCredentials, verifyGoogleCredentials } from '../actions'
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

export default function Login(){
    const { setUser } = useUser();
    const classes = useStyles()
    const navigate = useNavigate()
    const location = useLocation()

    const [username, setUsername] = React.useState()
    const [password, setPassword] = React.useState()
    const [errorMessage, setErrorMessage] = React.useState()

    const handleUsername = (value) => {
        setUsername(value)
    }
    
    const handlePassword = (value) => {
        setPassword(value)
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     loginUser({'username':username, 'password': password}).then(response =>{
    //         checkAuth().then(response => {
    //             setUser({ ...response}); 
    //             // navigate('/home/')
    //             const redirectTo = location.state?.from?.pathname || '/';
    //             navigate(redirectTo, { replace: true });
    //         })

    //     }).catch(error =>{
    //         setErrorMessage("Incorrect Username Password")
    //     })
    // }

    const handleLoginSuccess = (response) => {
        verifyGoogleCredentials(response.credential).then(response =>{
            checkAuth().then(response => {
                setUser({ ...response}); 
                const redirectTo = location.state?.from?.pathname || '/';
                navigate(redirectTo, { replace: true });
            })
        }).catch(error =>{
            setErrorMessage("Something went wrong. Please Try again.")
        })
        // Further processing like setting user context or navigating
    };
    
    const handleLoginError = (error) => {
        console.error('Login Error:', error);
        // Error handling like setting error message state
    };

    const responseFacebook = (response) => {
        console.log(response);
        const [firstName, ...lastNameParts] = response.name.split(' ')
        const lastName = lastNameParts.join(' ');

        verifyFacebookCredentials({'email':response.email, 'first_name':firstName, 'last_name':lastName, 'accessToken':response.accessToken}).then(response =>{
            checkAuth().then(response => {
                setUser({ ...response}); 
                navigate('/home/')
            })
        }).catch(error =>{
            setErrorMessage("Something went wrong. Please Try again.")
        })
        // Handle the response here (e.g., send it to your server)
    }
  
    return (
        <Grid container justifyContent="center" alignItems="center" mt={10}>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                <Typography variant ="h2">
                    Welcome back!
                </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} mt={3}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant ="title1">Sign in / Register</Typography>
                            </Grid>
                        <Grid item xs={12} sm = {12} md = {12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <GoogleLogin 
                                onSuccess={handleLoginSuccess} 
                                onError={handleLoginError} 
                                width={'300px'}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm = {12} md = {12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <FacebookLogin
                                appId="355530817236153" // Replace with your Facebook app ID
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                cssClass={classes.facebookButton}
                                icon="fa-facebook"
                            />
                        </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};