import { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyles } from '../../../styles';
import { Button, CircularProgress, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CardMedia,  } from '@mui/material';
import { useParams } from "react-router-dom";
import { getRental, getRentalbyToken } from '../../User/actions';
import { calculateTotalDays } from '../paymentHelper';
import { confirmPayment, createPaymentIntent, getTaxRate, sendRentalNotifications } from '../actions';

export default function Payment() {

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const {id} = useParams()

  const [rentalInformation, setRentalInformation] = useState({});
  const [numberOfDays, setNumberOfDays] = useState(0)
  const [total, setTotal] = useState(0)
  const [taxRate, setTaxRate] = useState()
  console.log(taxRate)

  const taxAmount = total * (taxRate/100)
  const trueTotal = taxAmount + total

  const [isLoading, setIsLoading] = useState(true)

  const [open, setOpen] = useState(false)
  const [openError, setOpenError] = useState(false)

  const onCloseDialog = () => {
    setOpen(false)
    navigate(`/renterprofile/`)
  }

  const onCloseErrorDialog = () => {
    setOpenError(false)
    navigate(`/payment/${id}`)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setOpenError(true)
    } else {
      console.log(trueTotal)
      const amount = trueTotal.toFixed(2)
      console.log(amount)
      createPaymentIntent({id: paymentMethod.id, 'total':amount , rentId:rentalInformation.id}).then(response=>{
        if(response.status === 200){
          // sendRentalNotifications({'rentId': rentalInformation.id})
          setOpen(true)
        }
        else{
          setOpenError(true)
        }
      })

      // Here you would pass paymentMethod.id to your server
    }
  };

  useEffect(() => {
    console.log(id)
    if(id){
      getRentalbyToken(id).then(response =>{
        if(response && response.length > 0){

          response = response[0]
          setRentalInformation(response)

          const numDays = calculateTotalDays(response.pickupDate, response.dropoffDate)
          setNumberOfDays(numDays)
          const total = calculateTotalDays(response.pickupDate, response.dropoffDate) * response.pricePerDay
          setTotal(total)
          getTaxRate({'rentId':response.id}).then(response2 => {
            setTaxRate(response2.taxRate)
            setIsLoading(false)
          })
        }
      })
    }
  }, [])

  

  return (
    <>
    {isLoading ? <CircularProgress/> :
    <Grid container spacing={3} justifyContent="center">
    <Grid item xs={12} sm={6} mt = {3}>
    <form onSubmit={handleSubmit}>
      <Paper style={{ padding: 16 }}>
        <Typography variant="h1" gutterBottom>
          Payment Details
        </Typography>
        <Grid item xs={12} sm={6} mb = {2}>
          <Typography variant="title1" gutterBottom>
            {rentalInformation.item.name}
          </Typography>
        </Grid>
          <CardMedia
              component="img"
              image={rentalInformation.itemImage.resizedImage250by250}
              title={rentalInformation.item.name}
              style={{
                width: '250px', // Set to the desired width
                // height: '250px', // Set to the desired height
                objectFit: 'contain', // This ensures the image retains its aspect ratio
              }}
          />
        <Grid container xs ={12}>
          <Grid item mt = {1} mb = {3} xs={12}>
            <Typography variant ="body1">
                {numberOfDays} {numberOfDays === 1 ? "day" : "days"} at ${(rentalInformation.pricePerDay).toFixed(2)} per day
            </Typography>
          </Grid>
          <Grid item mt = {1} mb = {1} xs={2}>
            <Typography variant ="body1">
                Subtotal 
            </Typography>
          </Grid>
          <Grid item mt = {1} mb = {1} xs={9}>
            ${total.toFixed(2)}
          </Grid>
          <Grid item mt = {1} mb = {1} xs={2}>
            <Typography variant ="body1">
                Sales Tax 
            </Typography>
          </Grid>
          <Grid item mt = {1} mb = {1} xs={9}>
            ${taxAmount.toFixed(2)}
          </Grid>
          
          <Grid item mt = {1} mb = {1} xs={2}>
            <Typography variant ="body1">
                Total
            </Typography>
          </Grid>
          <Grid item mt = {1} mb = {1} xs={9}>
            ${trueTotal.toFixed(2)}
          </Grid>
        </Grid>
        <Grid item mt = {1} mb = {3} xs={2}>
            <Typography variant ="h1">
                Billing Information
            </Typography>
        </Grid>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#ff6347',
              },
            },
          }}
        />
      </Paper>
      <Grid item mt={1}>
      <Button type='submit' variant='contained'>Pay</Button>
      </Grid>
      </form>
      <Dialog open={open} onClose={onCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Approval Sent</DialogTitle>
          <DialogContent>
              <DialogContentText>
                  Successful Payment. Thank you.
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={onCloseDialog} color="primary">
                  Go to Pending Rentals
              </Button>
          </DialogActions>
      </Dialog>
      <Dialog open={openError} onClose={onCloseErrorDialog} maxWidth="md" fullWidth>
          <DialogTitle>Something Went Wrong</DialogTitle>
          <DialogContent>
              <DialogContentText>
                We appreciate your patience. 
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={onCloseErrorDialog} color="primary">
                  Go back to payments
              </Button>
          </DialogActions>
      </Dialog>
    </Grid>
  </Grid>
  }
  </>
  );
}
