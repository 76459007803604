import React, { useState, useEffect } from "react";
import { Box, Autocomplete, Button, Grid, TextField, Typography, OutlinedInput, InputAdornment, Card, CardContent, CardActions, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { createItem, createImage, getItem, updateItem, getImages, deleteImage } from "../actions";
import { getUserStore } from '../../User/actions'
import { formatDateDB } from "../../util/formatters";
import { useNavigate, useParams } from "react-router-dom";
import { useStyles } from '../../../styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { useUser } from '../../User/userContext';
import CancellationPolicy from "../../../components/CancellationPolicy";
import { isEmptyObject } from "../../util/generalHelpers";
import { getImageFromUrl, handleImageUpload } from "../../util/imageHelper";
import { ImageUpload } from '../../../components/ImageUpload'
import { initAutocomplete, loadScript } from "../../util/locationHelpers";
import Compressor from 'compressorjs';

export default function UploadItem() {

    const classes = useStyles();
    const {id} = useParams()
    const { user } = useUser();
    const navigate = useNavigate();

    const [itemData, setItemData] = useState({})
    const [imagesData, setImagesData] = useState([])
    const [previewImage, setPreviewImage] = useState([]);
    const [dateRangeDisabled, setDateRangeDisabled] = useState(true);
    const [cancelationDisabled, setCancelationDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [defaultFreeTime, setDefaultFreeTime] = useState('3');
    const [partialTime, setPartialTime] = useState('1');
    const [partialFee, setPartialFee] = useState('50');
    const [fullFee, setFullFee] = useState('100');

    const [addressName, setAddressName] = useState()
    const [lat, setLat] = useState()
    const [long, setLong] = useState()
    const [placeID, setPlaceID] = useState()
    const [stores, setStores] = useState([])

    const [selectedStore, setSelectedStore] = useState()

    const [errorMessage, setErrorMessage] = useState()

    const [openStripeConnect,setOpenStripeConnect] = useState(false)

    const [valueMessage, showValueMessage] = useState(false)

    useEffect(() => {
        if(user){
            if(id){
                setIsLoading(true)
                const fetchData = async () =>{
                    console.log("HERE IN FETCH DATA")
                    const response = await getItem(id)
                    console.log("HERE WAITING ON RESPONSE")
                    if(response){
                        let obj = {...response}
                        setAddressName(response.addressName)
                        setLat(response.latitude)
                        setLong(response.longitude)
                        setPlaceID(response.place_id)
                        delete obj.addressName;
                        delete obj.latitude;
                        delete obj.longitude;

                        const response2 = await getUserStore(user.user.id)
                        console.log("HERE WAITING ON 2")
                        if(response2){
                            console.log(response2)
                            setStores(response2)
                            if(response.store){
                                const selectedArray = response2.filter(store => response.store.id === store.id)
                                if(selectedArray.length !== 0){
                                    setSelectedStore(selectedArray[0])
                                    obj['store_id'] = selectedArray[0].id
                                    setItemData(obj)
                                }
                            }
                            else{
                                setItemData(obj)
                            }
                            console.log("HERE IMAGES")
                            const response3 = await getImages(id)
                            console.log(response3)
                            const imageArray = []
                            if(response3 && response3.length > 0){
                                for (const image of response3) {
                                    const file = await getImageFromUrl(image.image);
                                    if (file) { // Make sure the file is not false or undefined
                                        imageArray.push({ id: image.id ,url: image.image, file });
                                    }
                                }
                                setImagesData(imageArray)
                            }
                            setIsLoading(false)
                        }
                    }
                }
                fetchData()
            }else{
                setItemData({'indefenitelyAvailable': 1, 'quantity': 1, 'minimum':1})
                    getUserStore(user.user.id).then(response2 => {
                        setStores(response2)
                        setIsLoading(false)
                    })
                }
        }
    }, [user])

    useEffect(() => {

    }, [itemData, stores, selectedStore])

    useEffect(() => {
        // Check if Google Maps script is already loaded
        if (document.getElementById('google-maps-script')) {
            if (window.google && window.google.maps && window.google.maps.places) {
                initAutocomplete(setItemLocation);
                console.log("ALREADY INITIALIZED")
            } else {
                loadScript(setItemLocation);
                console.log("LOADING")
            }
        } else {
            loadScript(setItemLocation);
            console.log("HERE")
        }
    }, [document.getElementById('autocompleteInput')]);

    const handleFileChange = (e) => {
        setImagesData(e.target.files);
        handleImageUpload(e, setPreviewImage, previewImage);
    };

    const handleData = (value, dataPoint) =>{
        setItemData(prevItemData => ({
            ...prevItemData,
            [dataPoint]: value
        }));
    }

    const setItemLocation = (name, latitude, longitude, placeIdTemp) => {
        setAddressName(name)
        setLat(latitude)
        setLong(longitude)
        setPlaceID(placeIdTemp)
    }


    const handleDateRangeSelection = (newRange) => {
        let obj = {...itemData}
        console.log("HERE ", newRange)
        obj['startDate'] = newRange[0] ? formatDateDB(newRange[0]): null
        obj['endDate'] = newRange[1] ? formatDateDB(newRange[1]): null
        setItemData(obj)
    };

    const handleValueCheck = (value) =>{
        if(user.canPostAnything){
            showValueMessage(false)
            return false
        }
        else if(value >= 300){
            showValueMessage(true)
            return true
        }
        else{
            showValueMessage(false)
            return false
        }
    }

    const validateData = (value) => {
        console.log("HERE")
        console.log(placeID)
        if(! (placeID) && !(itemData.useDefaultStoreLocation)){
            setErrorMessage("Please select valid address there bud")
            return false
        }
        if(! (imagesData && imagesData.length >= 1)){
            setErrorMessage("Please enter at least 1 photo")
            return false
        }
        if (handleValueCheck(itemData.estimatedValue)){
            console.log("HERE")
            setErrorMessage("Sorry we do not yet accomodate items with such high value. Please reach out to customer-support@yelorentals.com to discuss other solutions.") 
            return false
        }
        setErrorMessage()
        return true
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateData()){
            let obj = {...itemData}
            obj['dateEntered'] = formatDateDB(new Date())
            obj['userProfile_id'] = user.user.id
            obj['addressName'] = addressName
            obj['latitude'] = lat
            obj['longitude'] = long
            obj['place_id'] = placeID
            obj['cancelationFee'] = fullFee
            obj['freeCancelationUntil'] = defaultFreeTime
            obj['partialFeeCancelationUntil'] = partialTime
            obj['partialFee'] = partialFee
            obj['store_id'] = selectedStore ? selectedStore.id : null
            if(id){
                updateItem(id, obj).then(response=>{
                    const promises = []
                    for (let i = 0; i < imagesData.length; i++) {
                        if(imagesData[i].deleteFlag){
                            if(imagesData[i].id){
                                const promise = deleteImage(imagesData[i].id)
                                promises.push(promise)
                            }
                        }
                        else{
                            if(imagesData[i].id){
                                console.log("HERE IN OLD PHOTO")
                            }
                            else{
                                const formData = new FormData();
                                formData.append('image', imagesData[i].file);
                                formData.append('item', response.id)
                                const promise = createImage(formData)
                                promises.push(promise)
                            }
                        }
                    };
                    Promise.all(promises).then((results) =>{
                        console.log(user)
                        console.log(user.user.stripeConnectId)
                        if(user.user.stripeConnectId  === null){
                            setOpenStripeConnect(true)
                        }
                        else{
                            navigate('/ownerprofile/')
                        }
                    })
                })  
            }
            else{
                createItem(obj).then(response => {
                    const promises = []
                    for (let i = 0; i < imagesData.length; i++) {

                        const formData = new FormData();
                        formData.append('image', imagesData[i].file);
                        formData.append('item', response.id)
                        const promise = createImage(formData)
                        promises.push(promise)
                    }
                    Promise.all(promises).then((results) =>{
                        if(user.user.stripeConnectId === null){
                            setOpenStripeConnect(true)
                        }
                        else{
                            navigate('/ownerprofile/')
                        }
                    })
                })
            }
            
        }
        return false
    }

    const dayOptions = [1, 2, 3, 4, 5, 6, 7];

    return (
        <Grid container justifyContent="center" alignItems="center" ml = {1} mr = {1}>
            <Grid item xs={10} sm={6} md={6} mt={3}>
                        <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Typography variant ="h1">
                                    Put Your Item up for Rent!
                                </Typography>
                            </Grid>
                            {stores ? <Grid item xs={10} sm = {10} md = {8}>
                            <Typography variant ="body1" align="left">
                                    Store (optional)
                                </Typography >
                                <Autocomplete
                                    options={stores}
                                    getOptionLabel={(option) => option ? `${option.name} - ${option.addressName}` : ''}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" variant="outlined" />
                                    )}
                                    value = {selectedStore || null}
                                    onChange={(event, value) => {
                                        if(value){
                                            handleData(value.id, 'store_id');
                                            setSelectedStore(value)
                                            handleData(1, 'useDefaultStoreLocation')
                                            setCancelationDisabled(true)
                                        }
                                        else{
                                            handleData(null, 'store_id');
                                            setSelectedStore(null)
                                            handleData(0, 'useDefaultStoreLocation')
                                            setCancelationDisabled(false)
                                        }
                                    }}
                                />
                            </Grid> : null}
                            <Grid item xs={12} sm = {10} md = {10}>
                                <Typography variant ="body1" align="left">
                                    Name of Product
                                </Typography >
                                <TextField fullWidth required size = "small" value={itemData.name} onChange={(e)=> handleData(e.target.value, 'name')}/>
                            </Grid>
                            <Grid item xs={10} sm = {5} md = {5}>
                                <Typography variant ="body1">
                                    Estimated Value of Item 
                                </Typography>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    required
                                    size="small"
                                    type="number"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={itemData.estimatedValue} 
                                    onChange={(e)=> {handleData(e.target.value, 'estimatedValue'); handleValueCheck(e.target.value)}}
                                />
                            </Grid>
                            <Grid item xs={10} sm = {5} md = {5}>
                                {valueMessage ? <span style={{ color: 'red' }}>Sorry, we cannot yet accomodate items this high.</span>: null}
                            </Grid>
                            <Grid item xs={10} sm = {5} md = {5}>
                                <Typography variant ="body1">
                                    Price Per Day
                                </Typography>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    required
                                    size="small"
                                    type="number"
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    value={itemData.pricePerDay} 
                                    onChange={(e)=> {handleData(e.target.value, 'pricePerDay')}}
                                />
                            </Grid>
                            <Grid item xs={10} sm = {5} md = {5}>
                                <Typography variant ="body1">
                                    Minimum 
                                </Typography>
                                <Autocomplete
                                    options={dayOptions}
                                    getOptionLabel={(option) => option == 1 ? `${option} day` : `${option} days`}
                                    value={itemData.minimum}
                                    defaultValue={1}
                                    onChange={(event, newValue) => {
                                        handleData(newValue, 'minimum')
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" variant="outlined" />
                                    )}
                                    
                                    disableClearable
                                    size="small"
                                />
                            </Grid>
                        
                            <Grid item xs = {12} sm = {10} md = {10}>
                                <Typography>
                                    Description
                                </Typography>
                                <TextField required value={itemData.description} fullWidth multiline rows = {5} onChange={(e)=> handleData(e.target.value, 'description')}/>
                            </Grid>
                            {/* <Grid item xs = {12} sm = {10} md = {10}>
                                <Typography>
                                    Availability
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox checked={dateRangeDisabled} onChange={(e) => {handleData(e.target.checked ? 1 : 0, 'indefenitelyAvailable'); setDateRangeDisabled(!dateRangeDisabled)}} />}
                                    label="Indefinitely"
                                />
                                {!dateRangeDisabled ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateRangePicker']}>
                                        <DateRangePicker disabled = {dateRangeDisabled} localeText={{ start: 'Start', end: 'End' }} onChange={handleDateRangeSelection}/>
                                    </DemoContainer>
                                </LocalizationProvider>
                                : null}
                            </Grid> */}
                            <Grid item xs = {12} sm = {10} md = {10}>
                                <Typography>
                                    Pick up location (use a public address for your safety)
                                </Typography>
                                {
                                itemData.store_id ? <FormControlLabel
                                    control={<Checkbox checked={itemData.useDefaultStoreLocation} onChange={(e) => {handleData(e.target.checked ? 1 : 0, 'useDefaultStoreLocation')}} />}
                                    label="Use Store Location"
                                />: ''}
                                <TextField
                                    id="autocompleteInput" 
                                    variant="outlined" 
                                    fullWidth 
                                    disabled={itemData.useDefaultStoreLocation}
                                    required
                                    value = {itemData.useDefaultStoreLocation ? selectedStore.addressName : addressName}
                                    onChange = {(event) => {
                                        setAddressName(event.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: <div id="google-maps-script" />
                                    }} 
                                />
                            </Grid>
                            <Grid item xs = {12} sm = {12} md = {12}>
                                    <Typography>
                                        Cancelation Policy
                                    </Typography>
                                {/* {
                                itemData.store_id ? <FormControlLabel
                                    control={<Checkbox checked={itemData.useDefaultStoreCancelationPolicy} onChange={(e) => {handleData(e.target.checked ? 1 : 0, 'useDefaultStoreCancelationPolicy'); setCancelationDisabled(itemData.useDefaultStoreCancelationPolicy)}} />}
                                    label="Use Store Cancellation Policy"
                                />: ''} */}
                                <Grid item xs = {12} sm = {10} md = {10}>
                                    <CancellationPolicy
                                        show = {itemData.useDefaultStoreCancelationPolicy}
                                        defaultFreeTime = {defaultFreeTime} 
                                        setDefaultFreeTime = {setDefaultFreeTime} 
                                        partialTime = {partialTime}
                                        setPartialTime ={setPartialTime}
                                        partialFee = {partialFee}
                                        setPartialFee = {setPartialFee}
                                        fullFee = {fullFee}
                                        setFullFee = {setFullFee}
                                    />
                                </Grid>       
                            </Grid> 
                            <Grid item xs={10} mt={3}>
                                <ImageUpload images={imagesData} setImages={setImagesData} limit={5}/>
                            </Grid>
                            <Grid item xs={10} mt={3}>
                                <span style={{ color: 'red' }}>{errorMessage}</span>
                            </Grid>
                            <Grid item mt={3}>
                                <Button variant="contained" type="submit">Submit</Button>
                            </Grid>
                            <Dialog open={openStripeConnect} onClose={()=>{setOpenStripeConnect(false);navigate(`/ownerprofile`)}} maxWidth="md" fullWidth>
                                <DialogTitle>You do not have a connected Stripe Account</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Please create a Stripe Connected account so we can transfer your earnings. 
                                    </DialogContentText>
                                    <Grid item xs={12} mt={2}>
                                    <Button onClick={()=>{navigate('/billingaccounts')}}>
                                        Go to Billing Accounts
                                    </Button>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                    </Grid>
                    </form>
            </Grid>
        </Grid>
    )
}


